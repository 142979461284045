(function() {
    'use strict';

    angular.module('ionicLoadingTemplate', [
        'ionic',
        'uabDefaultVariable'
    ]);
})();
(function() {
    'use strict';

    angular.module('ionicLoadingTemplate').controller(
        'IonicLoadingTemplateController',
        IonicLoadingTemplateController
    );

    IonicLoadingTemplateController.$inject = [
        'DefaultVariableService',
        'IonicLoadingTemplateService'
    ];

    function IonicLoadingTemplateController(
        DefaultVariableService,
        IonicLoadingTemplateService
    ) {
        var IonicLoadingTemplateController = this;

        IonicLoadingTemplateController.toggleTemplate = toggleTemplate;
        function toggleTemplate(message, addToQueue) {
            message = DefaultVariableService.get(
                message,
                IonicLoadingTemplateController.message
            );

            return IonicLoadingTemplateService.toggleTemplate(message, addToQueue);
        }

        IonicLoadingTemplateController.reset = reset;
        function reset() {
            IonicLoadingTemplateController.message = '';
        }

        IonicLoadingTemplateController.init = init;
        function init() {
            IonicLoadingTemplateController.reset();
        }

        IonicLoadingTemplateController.init();
    }
})();
(function() {
    'use strict';

    angular.module('ionicLoadingTemplate').service(
        'IonicLoadingTemplateService',
        IonicLoadingTemplateService
    );

    IonicLoadingTemplateService.$inject = [
        'DefaultVariableService',
        '$ionicLoading',
        '$q'
    ];

    function IonicLoadingTemplateService(
        DefaultVariableService,
        $ionicLoading,
        $q
    ) {
        var IonicLoadingTemplateService = this;

        IonicLoadingTemplateService.addToQueue = addToQueue;
        function addToQueue(message) {
            var position = IonicLoadingTemplateService.getPosition(message);
            if (position) {
                var queuedMessage = IonicLoadingTemplateService.messages[position];

                if (DefaultVariableService.isDefined(queuedMessage.deferred)) {
                    return queuedMessage.deferred.promise;
                } else {
                    IonicLoadingTemplateService.messages.splice(position, 1);
                }
            }

            message = {
                message:  message,
                deferred: $q.defer()
            };

            IonicLoadingTemplateService.messages.push(message);

            IonicLoadingTemplateService.startQueue();

            return message.deferred.promise;
        }

        IonicLoadingTemplateService.displayTemplate = displayTemplate;
        function displayTemplate(message) {
            IonicLoadingTemplateService.currentMessage = message;

            return $ionicLoading.show({
                template: message
            });
        }

        IonicLoadingTemplateService.getPosition = getPosition;
        function getPosition(message) {
            var position = false;

            var messagesLength = IonicLoadingTemplateService.messages.length;
            for (var i = 0; i < messagesLength; i++) {
                var queuedMessage = IonicLoadingTemplateService.messages[i].message;

                if (queuedMessage.toLowerCase() === message.toLowerCase()) {
                    return i;
                }
            }

            return position;
        }

        IonicLoadingTemplateService.hideTemplate = hideTemplate;
        function hideTemplate() {
            return $ionicLoading.hide().then(
                function() {
                    IonicLoadingTemplateService.currentMessage = false;
                }
            );
        }

        IonicLoadingTemplateService.removeFromQueue = removeFromQueue;
        function removeFromQueue(message) {
            var position = IonicLoadingTemplateService.getPosition(message);
            if (position) {
                var messages = IonicLoadingTemplateService.messages.splice(position, 1);

                var item = messages[0];

                if (DefaultVariableService.isDefined(item.deferred)) {
                    item.deferred.resolve(true);
                }
            }

            var deferred = $q.defer();
            if (position) {
                deferred.resolve(true);
            } else {
                if (IonicLoadingTemplateService.currentMessage && IonicLoadingTemplateService.currentMessage === message) {
                    return IonicLoadingTemplateService.hideTemplate();
                } else {
                    deferred.reject(false);
                }
            }

            return deferred.promise;
        }

        IonicLoadingTemplateService.reset = reset;
        function reset() {
            IonicLoadingTemplateService.currentMessage = false;

            IonicLoadingTemplateService.isRunning = false;

            IonicLoadingTemplateService.messages = [];
        }

        IonicLoadingTemplateService.startQueue = startQueue;
        function startQueue() {
            var deferred = $q.defer();
            if (!IonicLoadingTemplateService.isRunning) {
                IonicLoadingTemplateService.isRunning = true;

                if (IonicLoadingTemplateService.messages.length === 0) {
                    IonicLoadingTemplateService.isRunning = false;
                } else {
                    var messages = IonicLoadingTemplateService.messages.splice(0, 1);

                    var item = messages[0];

                    var message = item.message;
                    deferred = item.deferred;

                    return IonicLoadingTemplateService.displayTemplate(message).then(
                        function(response) {
                            if (response) {
                                deferred.resolve(true);
                            } else {
                                deferred.reject(false);
                            }

                            return IonicLoadingTemplateService.startQueue();
                        }
                    );
                }
            }

            deferred.resolve(true);

            return deferred.promise;
        }

        IonicLoadingTemplateService.toggleTemplate = toggleTemplate;
        function toggleTemplate(message, addToQueue) {
            if (!DefaultVariableService.isDefined(addToQueue)) {
                return IonicLoadingTemplateService.toggleTemplate(
                    message,
                    IonicLoadingTemplateService.getPosition(message) !== -1
                );
            } else if (addToQueue) {
                return IonicLoadingTemplateService.addToQueue(message);
            } else {
                return IonicLoadingTemplateService.removeFromQueue(message);
            }
        }

        IonicLoadingTemplateService.reset();

        return IonicLoadingTemplateService;
    }
})();